import ModelElement from '@/models/ModelElement';
import Position from '@/models/Position';
import Point from '@/models/Point';
import ModelConfig from '@/models/ModelConfig';
import Edge from '@/models/Edge';

export default class MarkerDrawableHelper {
  public static calculatePositionOfMarker(
    modelElement: ModelElement,
    currentConfig: ModelConfig,
    index?: number
  ): Position | undefined {
    if (index !== undefined) {
      if (this.isEdge(modelElement)) {
        const centerPoint = this.centerPositionOfEdge(modelElement);
        const moveLeft = this.moveFromCenter(modelElement, currentConfig);
        const leftUpperBound = new Point(centerPoint.x - moveLeft, centerPoint.y - 15);
        let widthStep = moveLeft * 2;
        if (currentConfig.markerTypes.length > 1) {
          widthStep = (moveLeft * 2) / (currentConfig.markerTypes.length - 1);
          const markerPlacementPoint = new Point(leftUpperBound.x + widthStep * index - 30, leftUpperBound.y - 60);
          return new Position(markerPlacementPoint, new Point(0, 0));
        } else {
          const markerPlacementPoint = new Point(centerPoint.x - 15, centerPoint.y - 40);
          return new Position(markerPlacementPoint, new Point(0, 0));
        }
      } else {
        const width = modelElement.bounds.width;
        const leftUpperBound = modelElement.startPos;
        let widthStep = width;
        if (currentConfig.markerTypes.length > 1) {
          widthStep = width / (currentConfig.markerTypes.length - 1);
        }
        const markerPlacementPoint = new Point(leftUpperBound.x + widthStep * index - 15, leftUpperBound.y - 40);
        return new Position(markerPlacementPoint, new Point(0, 0));
      }
    }
  }

  public static centerPositionOfEdge(modelElement: ModelElement): Point {
    let xStartPoint = modelElement.endPos.x;
    let xDiff = modelElement.endPos.x - modelElement.startPos.x;
    if (modelElement.startPos.x > modelElement.endPos.x) {
      xStartPoint = modelElement.startPos.x;
      xDiff = modelElement.startPos.x - modelElement.endPos.x;
    }
    let yStartPoint = modelElement.endPos.y;
    let yDiff = modelElement.endPos.y - modelElement.startPos.y;
    if (modelElement.startPos.y > modelElement.endPos.y) {
      yStartPoint = modelElement.startPos.y;
      yDiff = modelElement.startPos.y - modelElement.endPos.y;
    }
    if (modelElement) {
      if (this.isEdge(modelElement)) {
        return new Point(xStartPoint - xDiff / 2, yStartPoint - yDiff / 2);
      }
    }

    return new Point(0, 0);
  }

  public static moveFromCenter(modelElement: ModelElement, currentConfig: ModelConfig): number {
    if (modelElement.bounds.width / 2 < 30 * currentConfig.markerTypes.length) {
      return (30 * currentConfig.markerTypes.length) / 2;
    } else {
      return modelElement.bounds.width / 4;
    }
  }

  public static isEdge(modelElement: ModelElement): boolean {
    return modelElement instanceof Edge;
  }
}
