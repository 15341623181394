




















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Moveable from '../MovableComponent.vue';
import ModelElement from '@/models/ModelElement';
import SVGSelectEvent from '@/models/SVGSelectEvent';
import Point from '@/models/Point';
import { mapActions, mapState } from 'vuex';
import { namespace, State } from 'vuex-class';
import Model from '@/models/Model';
import ModelConfig from '@/models/ModelConfig';
import SelectedModelElement from '@/models/SelectedModelElement';
import Edge from '@/models/Edge';
import DrawAreaBounds from '@/models/DrawAreaBounds';
import ModelBackgroundImage from '@/models/ModelBackgroundImage';
import ConfigSvgComponent from '@/components/Editor/ConfigSvgComponent.vue';
import Drawable from '@/models/drawables/Drawable';
import AreaDrawable from '@/models/drawables/AreaDrawable';
import Bounds from '@/models/Bounds';
import Node from '@/models/Node';
import Attribute from '@/models/Attribute';
import { v4 as uuidv4 } from 'uuid';
import Intersects from 'intersects';
import TextAnnotationDrawable from '@/models/drawables/TextAnnotationDrawable';
import TextAnnotation from '@/models/TextAnnotation';
import Position from '@/models/Position';
import EditorCanvasHelper from '@/serializer/EditorCanvasHelper';
import { findNodeWithId } from '@/serializer/helpers';
import NodeHelpers from '@/serializer/NodeHelpers';
import ModelElementMarker from '@/models/ModelElementMarker';
import { ModelElementMarkerTypeEnum } from '@/enums/ModelElementMarkerTypeEnum';
import SVGMarker from '@/components/Editor/SVGMarker.vue';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import { ResizeSensor } from 'css-element-queries';
import MarkerDrawable from '@/models/drawables/MarkerDrawable';
import Marker from '@/models/Marker';
import MarkerDrawableHelper from '@/models/drawables/MarkerDrawableHelper';
import { VueSelecto } from 'vue-selecto';
import { MovableMixin } from '@/mixins/EditorMixins';

const modelEditor = namespace('modelEditor');
const drawEdges = namespace('drawEdges');

@Component({
  components: { SVGMarker, SvgComponent: ConfigSvgComponent, Moveable, VueSelecto },
  computed: {
    ...mapState('modelEditor', [
      'scale',
      'baseSize',
      'selectedElements',
      'selectedGroup',
      'currentModelBackgroundImage',
    ]),
    ...mapState('drawEdges', ['connectModeEnabled', 'sourceNode', 'targetNode', 'connectionType']),
  },
  methods: {
    ...mapActions('modelEditor', {
      setScale: 'setScale',
      setSelectedElements: 'setSelectedElements',
      addSelectedElement: 'addSelectedElement',
      clearSelectedElements: 'clearSelectedElements',
      setSelectedGroup: 'setSelectedGroup',
      addEdgeToCurrentModel: 'addEdgeToCurrentModel',
    }),
    ...mapActions('drawEdges', ['setSourceNode', 'setTargetNode', 'setConnectModeEnabled']),
  },
})
export default class EditorCanvas extends mixins(Toasts, MovableMixin) {
  /**
   * Injected from store
   */
  public currentModelBackgroundImage!: ModelBackgroundImage;
  /**
   * Injected from store
   */
  public scale!: number;
  /**
   * Injected from store
   */
  public baseSize!: number;

  /**
   * CTRL key allows to multiselect elements
   */
  public isCTRLEnabled = false;
  /**
   * SHIFT key allows to override single element select
   */
  public isSHIFTEnabled = false;

  /**
   * Injected from store
   */
  public connectModeEnabled!: boolean;

  /**
   * Injected from store
   */
  public sourceNode!: Node;

  /**
   * Injected from store
   */
  public targetNode!: Node;

  /**
   * Injected from store
   */
  public connectionType!: string;

  @State('markers', { namespace: 'modelElementMarkers' })
  protected modelMarkers!: ModelElementMarker[];

  @State('selectedMarkerType', { namespace: 'modelElementMarkers' })
  protected selectedMarkerType!: ModelElementMarkerTypeEnum;

  @Prop({
    required: false,
    default: false,
  })
  protected showReviewState!: boolean;

  @modelEditor.Action
  public setScale!: (val: number) => void;

  @modelEditor.Action
  public setSelectedElements!: (selectedElements: SelectedModelElement[]) => void;

  @modelEditor.Action
  public addSelectedElement!: (selectedElement: SelectedModelElement) => void;

  @modelEditor.Action
  public clearSelectedElements!: () => void;

  @modelEditor.Action
  public setSelectedGroup!: (group: string | boolean) => void;
  @modelEditor.Action
  public addEdgeToCurrentModel!: (edge: ModelElement) => void;

  @drawEdges.Action
  private setSourceNode!: (node: ModelElement | undefined) => void;
  @drawEdges.Action
  private setTargetNode!: (node: ModelElement | undefined) => void;
  @drawEdges.Action
  private setConnectModeEnabled!: (value: boolean) => void;

  protected selectedGroup!: string | boolean;

  protected drawAreaBounds: DrawAreaBounds = new DrawAreaBounds();

  protected viewBox = '0 0 0 0';
  protected lastBounds: Bounds = new Bounds(0, 0);

  public mounted(): void {
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('keyup', this.handleKeyUp);

    let drawContainer = document.querySelector('#drawContainer');
    if (drawContainer) {
      new ResizeSensor(drawContainer, () => {
        this.calculateViewBox();
      });
    }
    this.$root.$on('reload-drawables', () => {
      this.loadDrawables();
    });
    this.$root.$on('reset-markers', () => {
      this.currentModel.nodes.forEach((node) => {
        this.resetMarkerPositions(node);
      });
      this.currentModel.edges.forEach((edge) => {
        this.resetMarkerPositions(edge);
      });
      this.currentModel.drawables.forEach((drawable) => {
        if (drawable instanceof AreaDrawable || drawable instanceof TextAnnotationDrawable) {
          this.resetMarkerPositions(drawable);
        }
      });
    });
  }

  public beforeDestroy(): void {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  protected handleKeyDown(event: KeyboardEvent): void {
    if (event.code.toLowerCase() === 'space') {
      event.preventDefault();
      event.stopPropagation();
      this.dragscrollEnabled = true;
    }
    if (['controlleft', 'controlright'].indexOf(event.code.toLowerCase()) > -1) {
      this.isCTRLEnabled = true;
    }

    if (['shiftleft', 'shiftright'].indexOf(event.code.toLowerCase()) > -1) {
      this.isSHIFTEnabled = true;
    }
  }

  protected handleKeyUp(event: KeyboardEvent): void {
    if (event.code.toLowerCase() === 'space') {
      event.preventDefault();
      event.stopPropagation();
      this.dragscrollEnabled = false;
    }

    if (['controlleft', 'controlright'].indexOf(event.code.toLowerCase()) > -1) {
      this.isCTRLEnabled = false;
    }

    if (['shiftleft', 'shiftright'].indexOf(event.code.toLowerCase()) > -1) {
      this.isSHIFTEnabled = false;
    }

    if (event.code.toLowerCase() === 'escape') {
      this.deselectElements();
      this.setConnectModeEnabled(false);
    }

    if (['delete', 'backspace'].indexOf(event.code.toLowerCase()) > -1) {
      if (this.selectedElements.length > 0) {
        this.deleteSelectedElements();
      }
    }
  }

  @Watch('currentModel') handleCurrentModelChange(): void {
    Vue.nextTick(() => {
      this.getBoundsOfDrawArea();
    });
  }

  @Watch('scale')
  public scaleChanged(): void {
    this.calculateViewBox(true);
    Vue.nextTick(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any
      (this.$refs.moveable as any).updateRect();
    });
  }

  @Watch('currentModel', { deep: true })
  public currentModelChanged(newVal: Model): void {
    if (newVal) {
      Vue.nextTick(() => {
        this.loadDrawables();
      });
    }
  }

  @Watch('selectedGroup')
  public selectedGroupChanged(newVal: string, oldVal: string): void {
    if (newVal && !oldVal) {
      Vue.nextTick(() => {
        this.selectGroup(newVal);
      });
    }
  }

  @Watch('connectModeEnabled')
  private handleConnectModeChanged(newVal): void {
    if (newVal && this.selectedElements.length === 1) {
      this.setSourceNode(this.selectedElements[0].modelElement);
    }
  }

  protected handleClickOnEdge(event: SVGSelectEvent, element: ModelElement): void {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();

    const singleselect = this.isSHIFTEnabled;
    const multiselect = this.isCTRLEnabled;

    if (element.target) {
      if (singleselect || !multiselect) {
        this.setSelectedElements([new SelectedModelElement(element, element.target)]);
      } else {
        this.setSelectedElements(this.selectedElements.concat(new SelectedModelElement(element, element.target)));
      }
      Vue.nextTick(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
        (this.$refs.moveable as any).updateRect();
      });
    }
  }

  private isNodeAlreadySelected(selectedElements: SelectedModelElement[], node: ModelElement): boolean {
    return (
      selectedElements.findIndex((selectedELement) => {
        return selectedELement.modelElement === node;
      }) > -1
    );
  }

  /**
   * Handles all the selection with priority:
   *   1. If element is assigned to a group
   *      -> select all elements of the group -> exit
   *   2. If element is not in a group
   *      -> there are already selected elements from parent-child relationship
   *      -> without CTRL
   *      -> deselect current selection
   *      -> go on with 3.
   *   3. If element is not in a group
   *      -> select all children
   *      -> if there were children
   *      -> exit
   *   4. If element is not in a group
   *      -> does not have children
   *      -> without CTRL: select only this element
   *   5. If element is not in a group
   *      -> does not have children
   *      -> with CTRL: add element to selection
   */
  public handleClick(event: SVGSelectEvent, element: ModelElement): void {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();

    if (event.drag) {
      this.lastSelectEvent = event.originalEvent;
    }

    const singleselect = this.isSHIFTEnabled;

    if (singleselect) {
      // select only this single element
      if (element.target) {
        this.setConnectModeEnabled(false);
        this.setSelectedGroup(false);
        this.setSelectedElements([new SelectedModelElement(element, element.target)]);
        return;
      }
    }

    let selectedElements: SelectedModelElement[] = [].slice.call(this.selectedElements);

    if (this.connectModeEnabled) {
      // don't allow connect to itself
      if (element === this.sourceNode) {
        this.showToast('Error', 'Connection to itself is not allowed!', 'danger');
        return;
      }

      // check if target node is allowed
      const edgeType = this.currentConfig.getEdgeType(this.connectionType);
      let allowedTargetNode = false;
      if (edgeType && edgeType.connects) {
        allowedTargetNode =
          edgeType.connects.find((connection) => {
            return connection.from === this.sourceNode.type && connection.to === element.type;
          }) !== undefined;

        if (edgeType.connects.length === 0) {
          allowedTargetNode = true;
        }

        // add code to check also on parent

        // if bidirectional check, if other direction is allowed
        if (edgeType.bidirectional) {
          const allowedBidirectionalNode =
            edgeType.connects.find((connection) => {
              return connection.to === this.sourceNode.type && connection.from === element.type;
            }) !== undefined;
          if (!allowedBidirectionalNode) {
            this.showToast('Error', 'Connection is not allowed!', 'danger');
            return;
          }
        }

        // check if there is already a connection
        const connectionExists =
          this.currentModel.edges.find((edge) => {
            return (
              edge.connectsFrom === this.sourceNode && edge.connectsTo === element && edge.type === this.connectionType
            );
          }) !== undefined;

        if (connectionExists) {
          this.showToast('Error', 'Connection already exists!', 'danger');
          return;
        }

        // check if target element has same parentId
        /* Let users be responsible to make connections that makes sense. Maybe refactor to specify in config
           if it is allowed to connect.
        if (this.sourceNode.parentId && element.parentId && this.sourceNode.parentId !== element.parentId) {
          this.showToast('Error', 'Connection between elements with different parents is not allowed!', 'danger');
          return;
        }
        */

        if (allowedTargetNode && element.target) {
          selectedElements.push(new SelectedModelElement(element, element.target));
          this.setSelectedElements(selectedElements);
          this.setTargetNode(element);
          this.deselectElements();
          Vue.nextTick(() => {
            this.connectNodes();
          });
        } else {
          this.showToast('Error', 'Target is not allowed for this connection type!', 'danger');
        }
      }

      return;
    }

    // group select
    if (element.groupId && element.groupId.length > 0 && !this.selectedGroup) {
      this.deselectElements();
      this.setSelectedGroup(element.groupId);
      return;
    }

    // if there is already a parent selection deselect all others when CTRL is not pressed
    if (!this.isDragging && !this.isCTRLEnabled) {
      this.deselectElements();
      selectedElements = [];
    }

    // children select
    let childrenSelected = false;
    // select with group id
    this.currentModel.nodes.forEach((node) => {
      // Checks if node has a parentId which matches the id of clicked element
      if (node.parentId && node.parentId === element.id) {
        // only add element if not already selected and not equal to clicked element
        if (node.target && node !== element && !this.isNodeAlreadySelected(selectedElements, node)) {
          selectedElements.push(new SelectedModelElement(node, node.target));
          childrenSelected = true;
        }
      }
    });

    if (childrenSelected && !this.isNodeAlreadySelected(selectedElements, element) && element.target) {
      selectedElements.push(new SelectedModelElement(element, element.target));
      this.setSelectedElements(selectedElements);
      return;
    }

    // single select
    const selectedElementIndex = selectedElements.findIndex((el) => {
      return el.target === element.target;
    });

    if (selectedElementIndex > -1) {
      if (this.isCTRLEnabled && !this.selectedGroup) {
        // remove from current selection if already selected and not in group
        selectedElements.splice(selectedElementIndex, 1);
        this.setSelectedElements(selectedElements);
      }
      // don't do anything if element is already selected
      return;
    }

    if (this.isCTRLEnabled || (this.selectedGroup && this.isCTRLEnabled)) {
      // add select to currently selected elements
      if (element.target) {
        this.addSelectedElement(new SelectedModelElement(element, element.target));
      }
    } else {
      // select only one element
      if (element.target) {
        this.setSelectedElements([new SelectedModelElement(element, element.target)]);
      }
    }
  }

  public handleSelectUp(e: MouseEvent, modelElement: ModelElement) {
    this.lastSelectEvent = null;
    // (this.moveable as Moveable).dragEnd(e);
  }

  protected loadDrawables(): void {
    // Inject annotations as drawables
    this.currentModel.nodes.forEach((node) => {
      this.addAnnotationsToModel(node);
    });

    this.currentModel.edges.forEach((edge) => {
      this.addAnnotationsToModel(edge);
    });

    this.currentModel.drawables.forEach((drawable) => {
      this.addAnnotationsToModel(drawable);
    });

    // Inject markers as drawables
    this.currentModel.nodes.forEach((node) => {
      this.addMarkerToModel(node);
    });

    this.currentModel.edges.forEach((edge) => {
      this.addMarkerToModel(edge);
    });

    this.currentModel.drawables.forEach((drawable) => {
      this.addMarkerToModel(drawable);
    });
  }

  protected addMarkerToModel(element: ModelElement): void {
    element.markers.forEach((marker) => {
      const findExisting = this.currentModel.drawables
        .filter((drawable) => drawable instanceof MarkerDrawable)
        .map((markerDrawable) => markerDrawable.id)
        .includes(marker.id);

      if (!findExisting) {
        const markerDrawable = new MarkerDrawable('MarkerDrawable', marker, element);
        this.currentModel.drawables.push(markerDrawable);
      }
    });
  }

  protected addAnnotationsToModel(element: ModelElement): void {
    element.annotations.forEach((annotation) => {
      const findExisting = this.currentModel.drawables
        .filter((drawable) => drawable instanceof TextAnnotationDrawable)
        .map((annotationDrawable) => annotationDrawable.id)
        .includes(annotation.id);

      if (!findExisting) {
        const annotationDrawable = new TextAnnotationDrawable('TextAnnotationDrawable', annotation, element);
        this.currentModel.drawables.push(annotationDrawable);
      }
    });
  }

  @Watch('currentConfig')
  protected autoplaceMissplacedMarkers(): void {
    let reset = false;
    this.currentModel.nodes.forEach((node) => {
      reset = this.autoplaceMissplacedModelElement(node);
    });
    this.currentModel.edges.forEach((edge) => {
      reset = this.autoplaceMissplacedModelElement(edge);
    });
    this.currentModel.drawables.forEach((drawable) => {
      reset = this.autoplaceMissplacedModelElement(drawable);
    });
    if (reset) {
      this.$root.$emit('reload-drawables');
    }
  }

  protected autoplaceMissplacedModelElement(element: ModelElement): boolean {
    element.markers.forEach((marker) => {
      if (
        marker.position.startPos.x == 0 &&
        marker.position.startPos.y == 0 &&
        marker.position.endPos.x == 0 &&
        marker.position.endPos.y == 0
      ) {
        Vue.nextTick(() => {
          this.resetPositionForSingleMarker(element, marker);
        });
        return true;
      }
    });
    return false;
  }

  protected resetMarkerPositions(element: ModelElement): void {
    if (!element) {
      element = this.selectedElements[0].modelElement;
    }
    element.markers.forEach((marker) => {
      this.resetPositionForSingleMarker(element, marker);
    });
    this.$root.$emit('reload-drawables');
  }

  protected resetPositionForSingleMarker(element: ModelElement, marker: Marker): void {
    const resetPosition = MarkerDrawableHelper.calculatePositionOfMarker(
      element,
      this.currentConfig,
      this.getMarkerTypesIndexOfConfig(marker.type)
    );
    const existingDrawable = this.currentModel.drawables.find((drawable) => {
      return (drawable as MarkerDrawable).marker === marker;
    });
    if (existingDrawable instanceof MarkerDrawable && resetPosition) {
      existingDrawable.startPos = resetPosition.startPos;
    }
  }

  protected getMarkerTypesIndexOfConfig(type: string): number | undefined {
    const markerType = this.currentConfig.getMarkerType(type);
    if (markerType) {
      return this.currentConfig.markerTypes.indexOf(markerType);
    }
  }

  public handleMouseWheelScroll(event: WheelEvent): void {
    if (!this.isCTRLEnabled) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (event.deltaY > 0) {
      // zoom in
      let newScale = Math.round((this.scale + 0.1) * 100) / 100;
      // limit to maximum of 300%
      if (newScale > 3) {
        newScale = 3;
      }
      this.setScale(newScale);
    } else {
      // zoom out
      let newScale = Math.round((this.scale - 0.1) * 100) / 100;
      // limit to minimum of 10%
      if (newScale < 0.1) {
        newScale = 0.1;
      }
      this.setScale(newScale);
    }
  }

  public handleDeselectElements(e): void {
    if (!this.isDragging && !this.connectModeEnabled && !this.isResizing) {
      this.deselectElements();
    }
  }

  /**
   * Allow elements underneath current selection to be clicked
   * @param e clickGroup Event from moveable
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  public handleClickGroup(e: any): void {
    const inputTarget = e.inputTarget;

    if (inputTarget) {
      const selectTarget = e.inputTarget.closest('[data-element-id]');

      if (selectTarget) {
        const elementId = selectTarget.getAttribute('data-element-id');

        if (this.currentModel) {
          // node with element id
          const node = this.currentModel.nodes.find((node) => {
            return node.target === elementId || node.translateTarget === elementId;
          });

          const edge = this.currentModel.edges.find((edge) => {
            return edge.target === elementId || edge.translateTarget === elementId;
          });

          const drawable = this.currentModel.drawables.find((drawable) => {
            return drawable.target === elementId || drawable.translateTarget === elementId;
          });

          if (node) {
            this.handleClick(new SVGSelectEvent(new MouseEvent('click')), node);
          } else if (edge) {
            this.handleClick(new SVGSelectEvent(new MouseEvent('click')), edge);
          } else if (drawable) {
            this.handleClick(new SVGSelectEvent(new MouseEvent('click')), drawable);
          }
        }
      }
    }
  }

  protected deselectElements(): void {
    this.clearSelectedElements();
    this.setSelectedGroup(false);
    this.setConnectModeEnabled(false);
  }

  protected selectGroup(groupId: string): void {
    const selectedElements: SelectedModelElement[] = [];
    this.currentModel.nodes.forEach((node) => {
      if (node.groupId && node.groupId === groupId) {
        if (node.target) {
          selectedElements.push(new SelectedModelElement(node, node.target));
        }
      }
    });
    this.currentModel.edges.forEach((edge) => {
      if (edge.groupId && edge.groupId === groupId) {
        if (edge.target) {
          selectedElements.push(new SelectedModelElement(edge, edge.target));
        }
      }
    });
    this.currentModel.drawables.forEach((drawable) => {
      if (drawable.groupId && drawable.groupId === groupId) {
        if (drawable.target) {
          selectedElements.push(new SelectedModelElement(drawable, drawable.target));
        }
      }
    });

    this.setSelectedElements(selectedElements);

    /*    Vue.nextTick(() => {
      document.querySelectorAll('.group-' + groupId).forEach(element => {
        element.dispatchEvent(new Event('click'));
      });
    });

   */
  }

  protected getBoundsOfDrawArea(): void {
    if (this.$refs.drawArea) {
      const bbox = (this.$refs.drawArea as SVGSVGElement).getBBox();
      this.drawAreaBounds.bottom = bbox.height;
      this.drawAreaBounds.right = bbox.width;
    }
  }

  private connectNodes(): void {
    // get type of edge based on allowed types
    const edge = new Edge(
      this.connectionType,
      this.sourceNode.name + 'To' + this.targetNode.name,
      '',
      new Point(0, 0),
      this.sourceNode,
      this.targetNode
    );
    this.addEdgeToCurrentModel(edge);

    const edgeType = this.currentConfig.getEdgeType(this.connectionType);

    if (edgeType) {
      edgeType.attributeTypes.forEach((attributeType) =>
        edge.attributes.push(
          new Attribute(
            attributeType.variableName,
            attributeType.type.toLowerCase() === 'text' || attributeType.type.toLowerCase() === 'string' ? 'Text' : ''
          )
        )
      );

      if (edgeType.bidirectional) {
        const backEdge = new Edge(
          this.connectionType,
          this.sourceNode.name + 'To' + this.targetNode.name,
          '',
          new Point(0, 0),
          this.targetNode,
          this.sourceNode
        );
        edgeType.attributeTypes.forEach((attributeType) =>
          backEdge.attributes.push(new Attribute(attributeType.variableName, ''))
        );
        this.addEdgeToCurrentModel(backEdge);
      }
    }

    this.setTargetNode(undefined);
    this.setSourceNode(undefined);
    this.setConnectModeEnabled(false);
  }

  protected deleteSelectedElements(): void {
    if (this.connectModeEnabled) {
      return;
    }

    const newNodes = this.currentModel.nodes.filter((node) => {
      return (
        this.selectedElements.find((element) => {
          return element.modelElement === node;
        }) === undefined
      );
    });

    // remove id of deleted element from parentId of other nodes
    this.selectedElements.forEach((element) => {
      newNodes.forEach((node) => {
        if (node.parentId === element.modelElement.id) {
          node.parentId = null;
        }
      });
    });

    let newEdges = this.currentModel.edges.filter((edge) => {
      return (
        this.selectedElements.find((element) => {
          return element.modelElement === edge;
        }) === undefined &&
        //  both nodes existant
        newNodes.findIndex((node) => {
          return node === edge.connectsFrom;
        }) > -1 &&
        newNodes.findIndex((node) => {
          return node === edge.connectsTo;
        }) > -1
      );
    });

    // filter bidirectional edges
    const bidirectionalEdges = this.selectedElements.filter((element) => {
      const edgeType = this.currentConfig.getEdgeType(element.modelElement.type);
      return edgeType ? edgeType.bidirectional : false;
    });

    newEdges = newEdges.filter((edge) => {
      return (
        bidirectionalEdges.find((element) => {
          return (
            (element.modelElement as Edge).connectsFromId === edge.connectsToId &&
            (element.modelElement as Edge).connectsToId === edge.connectsFromId &&
            element.modelElement.type === edge.type
          );
        }) === undefined
      );
    });

    let newDrawables = this.currentModel.drawables.filter((drawable) => {
      const notSelected =
        this.selectedElements.find((element) => {
          return element.modelElement === drawable;
        }) === undefined;

      if (drawable instanceof TextAnnotationDrawable) {
        if (!notSelected) {
          // remove text annotation from node
          drawable.belongsTo.annotations.splice(drawable.belongsTo.annotations.indexOf(drawable.textAnnotation), 1);
        }
      }

      if (drawable instanceof MarkerDrawable) {
        if (!notSelected) {
          // remove marker from model element
          drawable.belongsTo.markers.splice(drawable.belongsTo.markers.indexOf(drawable.marker), 1);
        }
      }

      return notSelected;
    });

    // filter drawables if model element doesnt exists anymore
    newDrawables = newDrawables.filter((drawable) => {
      if (drawable instanceof TextAnnotationDrawable || drawable instanceof MarkerDrawable) {
        const nodeExists = newNodes.findIndex((node) => node.id && node.id === drawable.belongsTo.id) > -1;
        const edgeExists = newEdges.findIndex((edge) => edge.id && edge.id === drawable.belongsTo.id) > -1;
        const drawableExists =
          newDrawables.findIndex((otherDrawable) => otherDrawable.id && otherDrawable.id === drawable.belongsTo.id) >
          -1;

        return nodeExists || edgeExists || drawableExists;
      }

      return true;
    });

    this.currentModel.edges = newEdges;
    this.currentModel.nodes = newNodes;
    this.currentModel.drawables = newDrawables;

    this.setSelectedElements([]);
  }

  /**
   * Drop new element
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  protected handleDrop(e: any): void {
    const drawableType = e.dataTransfer.getData('new_element_drawable');
    const nodeType = e.dataTransfer.getData('new_element_node');

    const drawContainer = this.$refs.drawContainer as HTMLDivElement;

    // bounds of new element
    const drawableBounds = new Bounds(200, 200);

    // bounds of drawContainer
    const bounds = drawContainer.getBoundingClientRect();

    // calculate position
    const x =
      e.clientX / (1 / this.scale) -
      bounds.x / (1 / this.scale) -
      drawableBounds.width / 6 -
      this.internalViewPort.x / (1 / this.scale);
    const y =
      e.clientY / (1 / this.scale) -
      bounds.y / (1 / this.scale) -
      drawableBounds.height / 6 -
      this.internalViewPort.y / (1 / this.scale);

    if (drawableType.length > 0) {
      // dropped new element of type drawable
      let newDrawable: Drawable | undefined = undefined;
      let parentNode: Node | null = null;

      switch (drawableType) {
        case 'AreaDrawable':
          newDrawable = new AreaDrawable(drawableType, drawableType, '', new Point(x, y), drawableBounds);
          break;
        case 'TextAnnotationDrawable':
          // find element on this position
          for (const i in this.currentModel.nodes) {
            if (Object.prototype.hasOwnProperty.call(this.currentModel.nodes, i)) {
              const node = this.currentModel.nodes[i];

              if (
                Intersects.boxBox(x, y, x, y, node.startPos.x, node.startPos.y, node.bounds.width, node.bounds.height)
              ) {
                parentNode = node;

                if (parentNode) {
                  break;
                }
              }
            }
          }

          if (parentNode) {
            parentNode.annotations.push(
              new TextAnnotation(uuidv4(), '', new Position(new Point(x - 50, y - 50), new Point(x + 50, y + 50)))
            );
          }

          this.loadDrawables();
          // newDrawable = new TextAnnotationDrawable('TextAnnotationDrawable', new TextAnnotation('--'), '1');
          break;
        default:
          this.showToast('Not implemented yet', 'Drawable type not implemented yet', 'danger');
      }

      if (newDrawable) {
        newDrawable.endPos = new Point(x + drawableBounds.width, y + drawableBounds.height);

        this.currentModel.drawables.push(newDrawable);

        Vue.nextTick(() => {
          if (newDrawable && newDrawable.target) {
            this.setSelectedElements([new SelectedModelElement(newDrawable, newDrawable.target)]);
          }
        });
      }
    } else if (nodeType.length > 0) {
      const newNode = new Node(nodeType, nodeType, '', new Point(x, y));

      const configNodeType = this.currentConfig.getNodeType(nodeType);
      if (configNodeType) {
        configNodeType.attributeTypes.forEach((item) => {
          if (['width', 'height'].indexOf(item.variableName) > -1) {
            newNode.attributes.push(new Attribute(item.variableName, '300'));
          } else if ('abstract' == item.variableName) {
            newNode.attributes.push(new Attribute(item.variableName, 'false'));
          } else {
            newNode.attributes.push(new Attribute(item.variableName, item.displayName));
          }
        });

        newNode.attributes.push(new Attribute('internal_save_id', uuidv4()));

        this.currentModel.nodes.push(newNode);

        Vue.nextTick(() => {
          if (newNode && newNode.target) {
            // find out if element was dropped on other element
            // if yes -> create parent-child link
            let parentNode: Node | null = null;

            // only search for parent if element can have a parent
            // skip otherwise
            if (configNodeType.canHaveParent) {
              parentNode = NodeHelpers.findParentNode(newNode, this.currentModel.nodes, this.currentConfig);
              /*
              for (var i in this.currentModel.nodes) {
                if (this.currentModel.nodes.hasOwnProperty(i)) {
                  let node = this.currentModel.nodes[i];

                  if (node === newNode) {
                    continue;
                  }

                  let nodeType = this.currentConfig.getNodeType(node.type);

                  // skip calculcation if element type does not allow
                  // to have children
                  if (nodeType && nodeType.canHaveChildren) {
                    continue;
                  }

                  if (
                    Intersects.boxBox(
                      newNode.startPos.x,
                      newNode.startPos.y,
                      newNode.bounds.width,
                      newNode.bounds.height,
                      node.startPos.x,
                      node.startPos.y,
                      node.bounds.width,
                      node.bounds.height
                    )
                  ) {
                    parentNode = node;

                    if (parentNode) {
                      break;
                    }
                  }
                }
              }*/
            }

            if (parentNode) {
              newNode.parentNode = parentNode;
              newNode.parentId = parentNode.id;
            }
            Vue.nextTick(() => {
              if (newNode.target) {
                this.setSelectedElements([new SelectedModelElement(newNode, newNode.target)]);
              }
            });
          }
        });
      }
    }
  }

  protected allowDrop(e: Event): void {
    e.preventDefault();
  }

  get preparedDrawables(): Drawable[] {
    if (this.currentModel) {
      const drawables: Drawable[] = [].slice.call(this.currentModel.drawables);

      return drawables;
    }

    return [];
  }

  protected calculateViewBox(force?: boolean) {
    const sizeOffset = 50;
    let bounds = (this.$refs.drawContainer as HTMLElement).getBoundingClientRect();

    if (
      Math.abs(this.lastBounds.width - bounds.width) > sizeOffset ||
      Math.abs(this.lastBounds.height - bounds.height) > sizeOffset ||
      force
    ) {
      let windowWidth = window.innerWidth;
      let windowHeight = window.innerHeight;

      let width = windowWidth * (bounds.width / windowWidth) * this.scale;
      let height = windowHeight * (bounds.height / windowHeight) * this.scale;

      this.lastBounds = new Bounds(bounds.width, bounds.height);

      this.viewBox = '0 0 ' + width + ' ' + height;
    }
  }

  get svgViewbox(): string {
    let width = window.innerWidth * this.scale;
    let height = window.innerHeight * this.scale;

    return '0 0 ' + width + ' ' + height;
  }

  /**
   * Filter out bidirectional edges to draw only one edge
   */
  get preparedEdges(): Edge[] {
    if (this.currentModel) {
      const edges: Edge[] = [].slice.call(this.currentModel.edges);

      const preparedEdges: Edge[] = [];
      edges.forEach((edge) => {
        const edgeType = this.currentConfig.getEdgeType(edge.type);
        const isBidirectional = edgeType ? edgeType.bidirectional : false;

        if (isBidirectional) {
          const existingEdge = preparedEdges.find((e) => {
            return (
              e.type === edge.type &&
              ((e.connectsFromId === edge.connectsFromId && e.connectsToId === edge.connectsToId) ||
                (e.connectsToId === edge.connectsFromId && e.connectsFromId === edge.connectsToId))
            );
          });

          if (!existingEdge) {
            preparedEdges.push(edge);
          }
        } else {
          preparedEdges.push(edge);
        }
      });

      return preparedEdges;
    }

    return [];
  }

  protected onSelect(e) {
    // this.deselectElements();
    let selectedModelElements: SelectedModelElement[] = [];
    e.selected.forEach((el) => {
      let modelElementIdAttribute = el.attributes.getNamedItem('data-model-element-id');
      if (modelElementIdAttribute !== null) {
        let modelElement = this.currentModel.modelElements.find(
          (modelElement) => modelElement.id === modelElementIdAttribute.value
        );
        if (modelElement !== undefined && modelElement.target) {
          selectedModelElements.push(new SelectedModelElement(modelElement, modelElement.target));
        }
      }
    });

    this.setSelectedElements(selectedModelElements);
    Vue.nextTick(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
      (this.$refs.moveable as any).updateRect();
    });
  }
}
