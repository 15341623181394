import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class ExportFormats {
  @JsonProperty('displayName')
  private readonly _displayName: string;
  @JsonProperty('fileExtension')
  private readonly _fileExtension: string;

  constructor(displayName: string, fileExtension: string) {
    this._displayName = displayName;
    this._fileExtension = fileExtension;
  }

  get displayName(): string {
    return this._displayName;
  }

  get fileExtension(): string {
    return this._fileExtension;
  }
}
