import Node from '@/models/Node';
import ModelConfig from '@/models/ModelConfig';
import Intersects from 'intersects';
import ModelElement from '@/models/ModelElement';
import Edge from '@/models/Edge';
import { model } from '@/mocks/model.mock';

export default class NodeHelpers {
  public static findParentNode(node: Node, nodes: Node[], config: ModelConfig): Node | null {
    for (const i in nodes) {
      if (Object.prototype.hasOwnProperty.call(nodes, i)) {
        const n = nodes[i];

        if (n === node) {
          continue;
        }

        const nodeType = config.getNodeType(n.type);

        // skip calculcation if element type does not allow
        // to have children
        if (nodeType && !nodeType.canHaveChildren) {
          continue;
        }

        if (
          Intersects.boxBox(
            node.startPos.x,
            node.startPos.y,
            node.bounds.width,
            node.bounds.height,
            n.startPos.x,
            n.startPos.y,
            n.bounds.width,
            n.bounds.height
          )
        ) {
          return n;
        }
      }
    }
    return null;
  }

  public static getNodeName(modelElement: Node): string {
    const nameAttributes = ['id', 'name']; // order relevant for concatenation
    const delimiter = ' - ';

    let name = '';

    nameAttributes.forEach((nameAttribute) => {
      const attribute = modelElement.attributes.find((attribute) => attribute.name === nameAttribute);

      if (attribute) {
        if (name.length > 0) {
          name += delimiter;
        }
        name += attribute.value;
      }
    });

    if (name.length === 0) {
      name = modelElement.id;
    }

    return name;
  }

  public static getEdgeName(modelElement: Edge): string {
    let name = '';

    if (modelElement.connectsFrom) {
      name += "From '";
      name += this.getNodeName(modelElement.connectsFrom);
      name += "'";
    }

    if (modelElement.connectsTo) {
      name += " to '";
      name += this.getNodeName(modelElement.connectsTo);
      name += "'";
    }

    return name;
  }

  public static getName(modelElement: ModelElement): string {
    if (modelElement instanceof Node) {
      return NodeHelpers.getNodeName(modelElement);
    } else if (modelElement instanceof Edge) {
      return NodeHelpers.getEdgeName(modelElement);
    }

    return modelElement.type + ' - ' + modelElement.id;
  }
}
