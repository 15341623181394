import Model from '@/models/Model';
import MainService from '@/services/MainService';
import { deserialize, serialize } from 'typescript-json-serializer';
import { filterTemporaryDrawables } from '@/serializer/helpers';
import ExportFormats from '@/models/ExportFormats';
import ModelUpdate from '@/models/ModelUpdate';
import ModelAnalysisResult from '@/models/ModelAnalysisResult';
import { AxiosResponse } from 'axios';

export default class ModelService {
  private static mainURL = '/model';

  public static getAll(): Promise<Model[]> {
    return MainService.get(this.mainURL).then((response) => {
      return response.data.map((item) => deserialize(item, Model));
    });
  }

  public static getById(id: string): Promise<Model> {
    return MainService.get(this.mainURL + '/' + id + '/').then((response) => {
      return deserialize(response.data, Model);
    });
  }

  public static getByProjectId(projectId: number): Promise<Model[]> {
    return MainService.get(this.mainURL, { params: { projectId } }).then((response) => {
      return response.data.map((item) => deserialize(item, Model));
    });
  }

  public static save(model: Model): Promise<Model> {
    return MainService.post(this.mainURL, JSON.stringify(serialize(model))).then((response) => {
      return deserialize(response.data, Model);
    });
  }

  public static update(modelUpdate: ModelUpdate): Promise<Model> {
    modelUpdate.model = filterTemporaryDrawables(modelUpdate.model);
    // first save without edges
    return MainService.put(
      this.mainURL + '/' + modelUpdate.model.id + '/',
      JSON.stringify(serialize(modelUpdate))
    ).then((response) => {
      return deserialize(response.data, Model);
    });
  }

  public static delete(modelId: string): Promise<AxiosResponse> {
    return MainService.delete(this.mainURL + '/' + modelId + '/');
  }

  public static saveImageToModel(modelId: string, formData: FormData): Promise<AxiosResponse> {
    return MainService.post(this.mainURL + '/' + modelId + '/image', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  public static getImageByModelId(modelId: string): Promise<string> {
    return MainService.get(this.mainURL + '/' + modelId + '/image/').then((response) => {
      return response.data;
    });
  }

  public static deleteImageByModelId(modelId: string): Promise<AxiosResponse> {
    return MainService.delete(this.mainURL + '/' + modelId + '/image/');
  }

  public static getImageUrlIfImageExists(modelId: string): Promise<string> {
    return MainService.head(this.mainURL + '/' + modelId + '/image').then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.request.responseURL;
      }

      return null;
    });
  }

  public static getExportFormats(): Promise<ExportFormats[]> {
    return MainService.get(this.mainURL + '/exportFormats').then((response) => {
      return response.data.map((item) => deserialize(item, ExportFormats));
    });
  }

  public static export(modelId: string, format: string): Promise<string> {
    return MainService.get(this.mainURL + '/' + modelId + '/export', {
      params: { format },
      transformResponse: (res) => {
        // Avoid parsing JSON to Object
        return res;
      },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }

      return null;
    });
  }

  public static getImportFormats(): Promise<string[]> {
    return MainService.get(this.mainURL + '/importFormats').then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }

      return null;
    });
  }

  public static import(
    projectId: number,
    modelConfigId: string,
    model: string,
    modelName: string,
    format: string,
    modelId: string | null
  ): Promise<Model | null> {
    return MainService.post(this.mainURL + '/import', model, {
      params: { format, projectId, modelConfigId, modelName, modelId },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return deserialize(response.data, Model);
      }

      return null;
    });
  }

  public static getAnalysisTypes(): Promise<string[]> {
    return MainService.get(this.mainURL + '/analysis').then((response) => {
      return response.data;
    });
  }

  public static analyse(modelId: string, analysisType: string): Promise<ModelAnalysisResult> {
    return MainService.get(this.mainURL + '/' + modelId + '/analysis', { params: { analysis: analysisType } }).then(
      (response) => {
        return deserialize(response.data, ModelAnalysisResult);
      }
    );
  }
}
