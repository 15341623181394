















































import { Prop, Vue, Watch } from 'vue-property-decorator';
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapState } from 'vuex';
import Edge from '@/models/Edge';
import Node from '@/models/Node';
import ModelElement from '@/models/ModelElement';
import SVGSelectEvent from '@/models/SVGSelectEvent';
import Attribute from '@/models/Attribute';
import BaseSvgComponent from '@/components/Editor/BaseSvgComponent.vue';
import Component, { mixins } from 'vue-class-component';
import Bounds from '@/models/Bounds';
import Point from '@/models/Point';
import ModelConfig from '@/models/ModelConfig';
import EdgeType from '@/models/EdgeType';
import AllowedConnection from '@/models/AllowedConnection';
import NodeType from '@/models/NodeType';
import { SVG } from '@svgdotjs/svg.js';
import ReviewStateSvgComponent from '@/components/Editor/ReviewStateSvgComponent.vue';
import { namespace, State } from 'vuex-class';
import ReviewAssignment from '@/models/reviews/ReviewAssignment';
import { getCombinedStateOfReviewAssignments } from '@/serializer/ReviewStateHelper';
import { BTooltip } from 'bootstrap-vue';
import ModelElementType from '@/models/ModelElementType';
import EditorLayer from '@/models/EditorLayer';
import SelectedModelElement from '@/models/SelectedModelElement';
import { htmlEntities } from '@/serializer/helpers';
import EdgeText from '@/components/Editor/Edge/EdgeText.vue';
import EdgeClickHelper from '@/components/Editor/Edge/EdgeClickHelper.vue';
import { LayersMixins } from '@/mixins/LayersMixins';
import PathFinding, { PathFindingMechanism } from '@/pathfinding/PathFinding';

const modelEditorReviewStates = namespace('modelEditorReviewStates');

@Component({
  components: { EdgeClickHelper, EdgeText, ReviewStateSvgComponent },
  computed: {
    ...mapState('modelEditor', ['scale']),
    ...mapState('modelEditorReviewStates', ['reviewAssignments']),
  },
  methods: {
    ...mapActions('modelEditorReviewStates', ['getReviewAssignmentsOfNodeByModelElementId']),
  },
})
/**
 * Wraps a modelElement into an svg group. Uses the model config to display element.
 * Replaces placeholder with actual values of model element.
 */
export default class SvgComponent extends mixins(LayersMixins, BaseSvgComponent) {
  @Prop({
    required: true,
  })
  protected currentConfig!: ModelConfig;

  public scale!: number;

  get style(): { transform?: string } {
    if (this.modelElement) {
      return { transform: 'translate(' + this.modelElement.startPos.x + 'px, ' + this.modelElement.startPos.y + 'px)' };
    }

    return {};
  }

  get elementType(): ModelElementType | undefined {
    if (this.currentConfig && this.modelElement) {
      if (this.isEdge) {
        return this.currentConfig.getEdgeType(this.modelElement.type);
      } else if (this.isNode) {
        return this.currentConfig.getNodeType(this.modelElement.type);
      }
    }

    return undefined;
  }

  get layer(): EditorLayer | undefined {
    if (this.elementType && this.elementType.layer) {
      return this.getLayer(btoa(this.elementType.layer));
    }

    return undefined;
  }

  get visible(): boolean {
    if (this.layer) {
      return this.layer.visible;
    }

    return true;
  }

  get tooltipContent(): string {
    if (this.modelElement) {
      let tooltip = '';

      const draw = SVG('#svgjs-container');
      if (draw) {
        draw.svg(this.innerSVG);

        const p = draw.findOne('[data-original-text]');

        if (p) {
          if (p.attr('data-original-text')) {
            tooltip = p.attr('data-original-text');
          }
        }

        draw.clear();
      }

      return tooltip;
    }

    return '';
  }

  public $refs!: Vue['$refs'] & {
    svg_group: SVGElement;
    svg_content: SVGElement;
  };

  protected internalID = uuidv4();

  @Prop({ default: null, required: true })
  public componentPrototype?: string;

  @Prop({
    default: () => {
      return {};
    },
    required: true,
  })
  public modelElement?: ModelElement;

  @Prop({
    default: () => {
      return [];
    },
    required: false,
  })
  public connectedElements?: ModelElement[];

  protected deltaX = 0;
  protected deltaY = 0;

  @Prop({
    default: false,
    required: false,
  })
  protected showReviewStates!: boolean;

  @Prop({
    default: () => {
      return PathFindingMechanism.LINEAR;
    },
    required: false,
  })
  protected edgeRenderingType!: PathFindingMechanism;

  @modelEditorReviewStates.Action
  protected getReviewAssignmentsOfNodeByModelElementId!: (nodeId: string) => Promise<ReviewAssignment[]>;

  protected reviewAssignments!: ReviewAssignment[];
  protected localReviewAssignments: ReviewAssignment[] = [];

  protected tooltipDisabled = false;
  protected tooltipTimeout?: number = undefined;
  protected tooltipEnableDelay = 500;

  protected lastFromConnectPoint: Point | null = null;
  protected lastToConnectPoint: Point | null = null;

  @State('selectedElements', { namespace: 'modelEditor' })
  protected selectedElements!: SelectedModelElement[];

  constructor() {
    super();
  }

  @Watch('modelElement') handleModelElementPresent(newVal: ModelElement): void {
    if (newVal !== undefined) {
      this.disableTooltip();
      /**
       * Get bounds of element on first drawing of element
       */
      Vue.nextTick(() => {
        this.getBounds();
        this.enableTooltip(this.tooltipEnableDelay);
      });
    }
  }

  @Watch('modelElement.startPos') handleStartPosChanged(): void {
    this.disableTooltip();
    Vue.nextTick(() => {
      this.getBounds();
      this.enableTooltip(this.tooltipEnableDelay);
    });
  }

  @Watch('reviewAssignments')
  protected handleReviewAssignmentsChange(): void {
    this.loadReviewAssignmentsOfModelElement();
  }

  mounted(): void {
    if (this.modelElement) {
      if (this.isEdge) {
        Vue.set(this.modelElement, 'target', this.internalID + '-arrow');
      } else {
        Vue.set(this.modelElement, 'target', this.internalID + '-select');
      }
      Vue.set(this.modelElement, 'translateTarget', this.internalID + '-translate');
      Vue.set(this.modelElement, 'resizeTarget', this.internalID);
    }
    // quick-fix for preview modal
    Vue.nextTick(() => {
      Vue.nextTick(() => {
        this.getBounds();
      });
    });
    this.loadReviewAssignmentsOfModelElement();
  }

  updated(): void {
    if (this.modelElement) {
      if (this.isEdge) {
        Vue.set(this.modelElement, 'target', this.internalID + '-arrow');
      } else {
        Vue.set(this.modelElement, 'target', this.internalID + '-select');
      }
      Vue.set(this.modelElement, 'translateTarget', this.internalID + '-translate');
      Vue.set(this.modelElement, 'resizeTarget', this.internalID);
    }
  }

  @Watch('modelElement.target')
  protected handleWatchTarget(newVal: string): void {
    if (this.modelElement && newVal) {
      Vue.set(this.modelElement, 'target', this.internalID + '-select');
      Vue.set(this.modelElement, 'translateTarget', this.internalID + '-translate');
    }
  }

  protected loadReviewAssignmentsOfModelElement(): void {
    if (this.modelElement && this.modelElement.id) {
      this.getReviewAssignmentsOfNodeByModelElementId(this.modelElement.id).then((reviewAssignments) => {
        this.localReviewAssignments = reviewAssignments;
      });
    }
  }

  get reviewState(): string {
    return getCombinedStateOfReviewAssignments(this.localReviewAssignments);
  }

  get isValidReviewState(): boolean {
    return this.reviewState !== 'UNDEFINED';
  }

  public handleClick(event: MouseEvent): void {
    event.stopPropagation();
    if (this.isEdge) {
      this.$emit('svg-select', new SVGSelectEvent(event));
    }
  }

  public handleMouseDown(event: MouseEvent): void {
    event.stopPropagation();
    this.$emit('svg-select-down', new SVGSelectEvent(event, true));
  }

  public handleMouseUp(event: MouseEvent): void {
    event.stopPropagation();
    this.$emit('svg-select-up', new SVGSelectEvent(event));
  }

  get elementContent(): Attribute[] {
    if (this.modelElement) {
      return this.modelElement.attributes;
    }
    return [];
  }

  get isSelected(): boolean {
    return (
      this.selectedElements.find((selectedElement) => {
        return this.modelElement && selectedElement.modelElement.id === this.modelElement.id;
      }) !== undefined
    );
  }

  get getText(): string {
    if (this.isEdge && this.modelElement) {
      const textAttribute = this.modelElement.attributes.find((attribute) => attribute.name === 'text');
      if (textAttribute) {
        // https://stackoverflow.com/a/784547
        return '<div><p>' + htmlEntities(textAttribute.value).replace(/(?:\r\n|\r|\n)/g, '<br />') + '</p></div>';
      }
    }

    return '';
  }

  get centerPosition(): Point {
    if (this.modelElement) {
      if (this.isEdge && this.lastFromConnectPoint && this.lastToConnectPoint) {
        return new Point(
          (this.lastFromConnectPoint.x + this.lastToConnectPoint.x) / 2 - this.modelElement.startPos.x,
          (this.lastFromConnectPoint.y + this.lastToConnectPoint.y) / 2 - this.modelElement.startPos.y
        );
      }
      return new Point(this.modelElement.bounds.width / 2, this.modelElement.bounds.height / 2);
    }

    return new Point(0, 0);
  }

  get groupData(): string[] {
    const classes: string[] = [];
    if (this.modelElement && this.modelElement.groupId) {
      classes.push('group-' + this.modelElement.groupId);
    }
    return classes;
  }

  get innerSVG(): string {
    let innerSVG = this.componentPrototype ?? '';

    for (const i in this.elementContent) {
      if (Object.prototype.hasOwnProperty.call(this.elementContent, i)) {
        const attribute = this.elementContent[i];
        const regex = new RegExp('{{attribute_' + attribute.name + '}}', 'g');

        if (innerSVG) {
          const value = attribute.value;
          innerSVG = innerSVG.replace(regex, htmlEntities(value));
        }
      }
    }

    const regexName = new RegExp('{{name}}', 'g');
    if (innerSVG) {
      if (this.modelElement?.name != null) {
        innerSVG = innerSVG.replace(regexName, htmlEntities(this.modelElement?.name));
      }
    }

    if (innerSVG) {
      const draw = SVG('#svgjs-container');
      if (draw !== null) {
        draw.svg(innerSVG);

        if (draw.children().length > 0) {
          draw.children()[0].attr('data-element-id', this.internalID);

          // replace all text elements by foreign objects with html content to enable multiline text
          draw.find('text').forEach((element) => {
            if (this.modelElement) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
              const foreignObject = (draw as any).foreignObject(
                this.modelElement.bounds.width,
                this.modelElement.bounds.height
              );

              const styleAttributes: string[] = [];
              if (element.attr('font-size')) {
                styleAttributes.push('font-size: ' + element.attr('font-size') + 'px;');
              }

              if (element.attr('fill')) {
                styleAttributes.push('color: ' + element.attr('fill') + ';');
              }

              if (this.isAbstract) {
                styleAttributes.push('font-style: italic;');
              }

              let attributeString = '';
              attributeString += ' style="' + styleAttributes.join(' ') + '"';

              // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
              let text = (element as any).text();
              const originalText = text;

              if (text.length > 35) {
                text = text.slice(0, 35) + '...';
              }

              element.replace(
                foreignObject.add(
                  SVG(
                    '<p data-original-text="' +
                      originalText +
                      '" ' +
                      attributeString +
                      '>' +
                      htmlEntities(text) +
                      '</p>'
                  )
                )
              );
            }
          });
        }

        innerSVG = draw.svg(false);

        draw.clear();
      }
    }

    if (this.isEdge) {
      // start

      if (this.connectedElements && this.modelElement) {
        const from: Node = (this.modelElement as Edge).connectsFrom;
        const to: Node = (this.modelElement as Edge).connectsTo;

        if (innerSVG && from && to && this.currentConfig) {
          // get edge type from config
          const edgeType: EdgeType | undefined = this.currentConfig.getEdgeType(this.modelElement.type);

          if (!edgeType) {
            return '';
          }

          // test if connection is allowed
          const allowedConnection: {
            allowed: boolean;
            connection: AllowedConnection | null;
          } = edgeType.isConnectionAllowed(from.type, to.type);
          if (!allowedConnection.allowed) {
            // console.log('Connection not allowed from ' + from.type + ' to ' + to.type);
            return '';
          }

          const fromNodeType: NodeType | undefined = this.currentConfig.getNodeType(from.type);
          const toNodeType: NodeType | undefined = this.currentConfig.getNodeType(to.type);

          if (!fromNodeType || !toNodeType) {
            // console.log('No types found');
            return '';
          }

          /*
          if (fromNodeType.connectPoints.length === 0) {
            // console.log('No connect points for type ' + from.type);
            return;
          }

          if (toNodeType.connectPoints.length === 0) {
            // console.log('No connect points for type ' + to.type);
            return;
          }
          */

          this.lastFromConnectPoint = null;
          this.lastToConnectPoint = null;

          let lastDist = Number.MAX_VALUE;

          for (const i in fromNodeType.connectPoints) {
            if (Object.prototype.hasOwnProperty.call(fromNodeType.connectPoints, i)) {
              const fromConnectPoint = fromNodeType.connectPoints[i];
              if (allowedConnection.connection && !allowedConnection.connection.isFromPointAllowed(fromConnectPoint)) {
                // Point not allowed, look for another point
                /* console.log(
                  'Source direction "' +
                    fromConnectPoint +
                    '" not allowed from "' +
                    from.type +
                    '" to "' +
                    to.type +
                    '"'
                );*/
                continue;
              }
              for (const j in toNodeType.connectPoints) {
                if (Object.prototype.hasOwnProperty.call(toNodeType.connectPoints, j)) {
                  const toConnectPoint = toNodeType.connectPoints[j];

                  if (allowedConnection.connection && !allowedConnection.connection.isToPointAllowed(toConnectPoint)) {
                    // Point not allowed, look for another point
                    /* console.log(
                      'Destination direction "' +
                        toConnectPoint +
                        '" not allowed from "' +
                        from.type +
                        '" to "' +
                        to.type +
                        '"'
                    ); */
                    continue;
                  }

                  const realFromPoint: Point = new Point(
                    from.startPos.x + fromConnectPoint.x,
                    from.startPos.y + fromConnectPoint.y
                  );

                  const realToPoint: Point = new Point(
                    to.startPos.x + toConnectPoint.x,
                    to.startPos.y + toConnectPoint.y
                  );

                  const dist: number = Math.sqrt(
                    Math.pow(realToPoint.x - realFromPoint.x, 2) + Math.pow(realToPoint.y - realFromPoint.y, 2)
                  );

                  if (dist < lastDist) {
                    lastDist = dist;
                    this.lastFromConnectPoint = realFromPoint;
                    this.lastToConnectPoint = realToPoint;
                  }
                }
              }
            }
          }

          // no connect points matched
          // try bruteforcing two points
          if (!this.lastFromConnectPoint && !this.lastToConnectPoint) {
            const fromCenterX = from.startPos.x + from.bounds.width / 2;
            const fromCenterY = from.startPos.y + from.bounds.height / 2;

            const toCenterX = to.startPos.x + to.bounds.width / 2;
            const toCenterY = to.startPos.y + to.bounds.height / 2;

            lastDist = Number.MAX_VALUE;
            // find matching connect point nearest to center
            if (fromNodeType.connectPoints.length > 0 && toNodeType.connectPoints.length === 0) {
              for (const i in fromNodeType.connectPoints) {
                if (Object.prototype.hasOwnProperty.call(fromNodeType.connectPoints, i)) {
                  const fromConnectPoint = fromNodeType.connectPoints[i];
                  const realFromPoint: Point = new Point(
                    from.startPos.x + fromConnectPoint.x,
                    from.startPos.y + fromConnectPoint.y
                  );

                  const intersection2: false | number[] = this.lineRect(
                    realFromPoint.x,
                    realFromPoint.y,
                    toCenterX,
                    toCenterY,
                    to.startPos.x,
                    to.startPos.y,
                    to.bounds.width,
                    to.bounds.height
                  );

                  if (!isNaN(intersection2[0]) && !isNaN(intersection2[1])) {
                    const dist: number = Math.sqrt(
                      Math.pow(intersection2[0] - realFromPoint.x, 2) + Math.pow(intersection2[1] - realFromPoint.y, 2)
                    );
                    if (dist < lastDist) {
                      this.lastFromConnectPoint = realFromPoint;
                      this.lastToConnectPoint = new Point(intersection2[0], intersection2[1]);
                      lastDist = dist;
                    }
                  }
                }
              }
            } else if (fromNodeType.connectPoints.length == 0 && toNodeType.connectPoints.length > 0) {
              for (const i in toNodeType.connectPoints) {
                if (Object.prototype.hasOwnProperty.call(toNodeType.connectPoints, i)) {
                  const toConnectPoint = toNodeType.connectPoints[i];
                  const realToPoint: Point = new Point(
                    to.startPos.x + toConnectPoint.x,
                    to.startPos.y + toConnectPoint.y
                  );

                  const intersection: false | number[] = this.lineRect(
                    fromCenterX,
                    fromCenterY,
                    realToPoint.x,
                    realToPoint.y,
                    from.startPos.x,
                    from.startPos.y,
                    from.bounds.width,
                    from.bounds.height
                  );

                  if (!isNaN(intersection[0]) && !isNaN(intersection[1])) {
                    const dist: number = Math.sqrt(
                      Math.pow(intersection[0] - realToPoint.x, 2) + Math.pow(intersection[1] - realToPoint.y, 2)
                    );
                    if (dist < lastDist) {
                      this.lastFromConnectPoint = new Point(intersection[0], intersection[1]);
                      this.lastToConnectPoint = realToPoint;
                      lastDist = dist;
                    }
                  }
                }
              }
            }
          }

          // no connect points were defined
          // try bruteforcing two points
          if (!this.lastFromConnectPoint && !this.lastToConnectPoint) {
            if (from.bounds && to.bounds) {
              const fromCenterX = from.startPos.x + from.bounds.width / 2;
              const fromCenterY = from.startPos.y + from.bounds.height / 2;

              const toCenterX = to.startPos.x + to.bounds.width / 2;
              const toCenterY = to.startPos.y + to.bounds.height / 2;

              const intersection: false | number[] = this.lineRect(
                fromCenterX,
                fromCenterY,
                toCenterX,
                toCenterY,
                from.startPos.x,
                from.startPos.y,
                from.bounds.width,
                from.bounds.height
              );
              const intersection2: false | number[] = this.lineRect(
                fromCenterX,
                fromCenterY,
                toCenterX,
                toCenterY,
                to.startPos.x,
                to.startPos.y,
                to.bounds.width,
                to.bounds.height
              );

              if (!isNaN(intersection[0]) && !isNaN(intersection[1])) {
                this.lastFromConnectPoint = new Point(intersection[0], intersection[1]);
              }

              if (!isNaN(intersection2[0]) && !isNaN(intersection2[1])) {
                this.lastToConnectPoint = new Point(intersection2[0], intersection2[1]);
              }
            }
          }

          if (this.lastFromConnectPoint && this.lastToConnectPoint) {
            const draw = SVG('#svgjs-container');
            if (draw) {
              // load prototype into svg object
              const arrow = draw.svg(innerSVG);

              const id = uuidv4();

              this.deltaX = this.lastToConnectPoint.x - this.lastFromConnectPoint.x;
              this.deltaY = this.lastToConnectPoint.y - this.lastFromConnectPoint.y;

              // find path that represents arrow; Not the markers
              const arrowPath = arrow.findOne('g > path');
              if (arrowPath) {
                let segpts = PathFinding.getSegmentPoints(
                  {
                    startPoint: this.lastFromConnectPoint,
                    endPoint: this.lastToConnectPoint,
                    startNode: from,
                    endNode: to,
                  },
                  this.edgeRenderingType
                );

                let attrString = '';
                attrString += 'M0,0';
                for (let i in segpts) {
                  let seg = segpts[i];
                  attrString += ' L ' + seg[0] + ',' + seg[1];
                }

                arrowPath.attr('d', attrString);

                this.modelElement.position.startPos = this.lastFromConnectPoint;
                this.modelElement.position.endPos = this.lastToConnectPoint;

                const markers = ['marker-end', 'marker-start'];

                markers.forEach((item) => {
                  const marker = arrowPath.attr(item);

                  if (marker && marker.length > 0) {
                    const newMarker = marker.replace('url(', '').replace(')', '');

                    arrowPath.attr(item, 'url(' + newMarker + '-' + id + ')');
                  }
                });
              }

              const arrowMarkers = arrow.find('g > defs > marker');
              if (arrowMarkers) {
                arrowMarkers.forEach((item) => {
                  item.attr('id', item.attr('id') + '-' + id);
                });
              }

              innerSVG = arrow.svg(false);

              // arrow.remove();
              draw.clear();
            }
          }
        }
      }
    }

    return innerSVG;
  }

  /*
   * http://www.jeffreythompson.org/collision-detection/line-rect.php
   */
  private lineRect(x1: number, y1: number, x2: number, y2: number, rx: number, ry: number, rw: number, rh: number) {
    // check if the line has hit any of the rectangle's sides
    // uses the Line/Line function below
    const left = this.lineLine(x1, y1, x2, y2, rx, ry, rx, ry + rh);
    const right = this.lineLine(x1, y1, x2, y2, rx + rw, ry, rx + rw, ry + rh);
    const top = this.lineLine(x1, y1, x2, y2, rx, ry, rx + rw, ry);
    const bottom = this.lineLine(x1, y1, x2, y2, rx, ry + rh, rx + rw, ry + rh);

    // if ANY of the above are true, the line
    // has hit the rectangle
    if (left) {
      left[0] -= 10;
      return left;
    } else if (right) {
      right[0] += 10;
      return right;
    } else if (top) {
      top[1] -= 10;
      return top;
    } else if (bottom) {
      bottom[1] += 10;
      return bottom;
    }

    return false;
  }

  /*
   * http://www.jeffreythompson.org/collision-detection/line-rect.php
   */
  private lineLine(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number) {
    // calculate the direction of the lines
    const uA = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1));
    const uB = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1));

    // if uA and uB are between 0-1, lines are colliding
    if (uA >= 0 && uA <= 1 && uB >= 0 && uB <= 1) {
      // optionally, draw a circle where the lines meet
      const intersectionX = x1 + uA * (x2 - x1);
      const intersectionY = y1 + uA * (y2 - y1);

      return [intersectionX, intersectionY];
    }
    return false;
  }

  get isEdge(): boolean {
    return this.modelElement instanceof Edge;
  }

  get isNode(): boolean {
    return this.modelElement instanceof Node;
  }

  protected getBounds(): void {
    if (this.modelElement) {
      let width = 0;
      let height = 0;
      if (this.$refs.svg_group) {
        if (typeof (this.$refs.svg_group as SVGSVGElement).getBBox === 'function') {
          const bBox = (this.$refs.svg_group as SVGSVGElement).getBBox();
          if (bBox) {
            width = bBox.width;
            height = bBox.height;
          }
        }
      }
      this.modelElement.bounds = new Bounds(width, height);
    }
  }

  protected disableTooltip(): void {
    this.tooltipDisabled = true;
    if (this.$refs.tooltip) {
      (this.$refs.tooltip as BTooltip).$emit('close');
    }
  }

  protected enableTooltip(timeout?: number): void {
    if (!timeout) {
      timeout = 0;
    }
    clearTimeout(this.tooltipTimeout);
    this.tooltipTimeout = setTimeout(() => {
      this.tooltipDisabled = false;
    }, timeout);
  }

  get isAbstract(): boolean {
    if (this.modelElement) {
      const attribute = this.modelElement.attributes.find((attribute) => attribute.name === 'abstract');
      if (attribute) {
        return attribute.value === 'true';
      }
    }

    return false;
  }
}
