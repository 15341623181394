<template>
  <div></div>
</template>
<script lang="js">
/**
 * Component wraps movable library into.
 */
import Moveable, { EVENTS, PROPERTIES, METHODS } from 'moveable';

const watchReactiveProp = (key, deep) => ({
  handler(newValue) {
    const existingValue = this.moveable[key];
    if (existingValue === newValue) return;
    this.moveable[key] = newValue;
  },
  deep,
});
const watchMoveableProps = () =>
  PROPERTIES.reduce((acc, prop) => {
    if (prop === 'target' || prop === 'elementGuidelines' || prop === 'container' || prop === 'dragTarget') {
      acc[prop] = watchReactiveProp(prop, false);
    } else {
      acc[prop] = watchReactiveProp(prop, true);
    }
    return acc;
  }, {});
const methodMap = {};
METHODS.forEach((name) => {
  methodMap[name] = function func(...args) {
    return this.moveable[name](...args);
  };
});
export default {
  name: 'Moveable',
  inheritAttrs: false,
  props: {
    snappable: [Boolean, Array],
    snapCenter: Boolean,
    snapHorizontal: Boolean,
    snapVertical: Boolean,
    snapElement: Boolean,
    snapGap: Boolean,
    snapThreshold: Number,
    snapDigit: Number,
    isDisplaySnapDigit: Boolean,
    horizontalGuidelines: Array,
    verticalGuidelines: Array,
    elementGuidelines: Array,
    bounds: Object,
    innerBounds: Object,
    defaultGroupRotate: Number,
    scrollable: Boolean,
    scrollContainer: [HTMLElement, SVGElement],
    scrollThreshold: Number,
    getScrollPosition: Function,
    warpable: Boolean,
    renderDirections: Array,
    rotatable: Boolean,
    rotationPosition: String,
    throttleRotate: Number,
    pinchable: [Boolean, Array],
    scalable: Boolean,
    throttleScale: Number,
    keepRatio: Boolean,
    resizable: Boolean,
    throttleResize: Number,
    baseDirection: Array,
    draggable: Boolean,
    throttleDrag: Number,
    throttleDragRotate: Number,
    /*container: {
        type: [HTMLElement, SVGElement],
        default: () => document.body,
    },*/
    container: String,
    rootContainer: HTMLElement,
    dragArea: Boolean,
    origin: Boolean,
    zoom: Number,
    transformOrigin: [Array, String],
    edge: Boolean,
    ables: Array,
    className: String,
    pinchThreshold: Number,
    triggerAblesSimultaneously: Boolean,
    target: Array,
  },
  methods: {
    ...methodMap,
  },
  mounted() {
    const props = Object.keys(this.$props)
      .filter((key) => ['dragArea', 'container'].join(',').indexOf(key) <= -1)
      .reduce((res, key) => {
        res[key] = this.$props[key];
        return res;
      }, {});

    this.moveable = new Moveable(document.querySelector(this.$props.container), {
      ...props,
    });
    EVENTS.forEach((event) => this.moveable.on(event, this.$emit.bind(this, event)));
    window.addEventListener('resize', this.updateRect, { passive: true });
  },
  watch: {
    ...watchMoveableProps(),
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateRect);
    this.moveable.destroy();
  },
};
</script>
