
























import { Prop, Vue, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import Point from '@/models/Point';

@Component({})
export default class EdgeText extends Vue {
  public $refs!: Vue['$refs'] & {
    arrowtext: SVGForeignObjectElement;
    arrowtext_sizer: SVGForeignObjectElement;
  };

  @Prop({
    default: () => '',
    required: true,
  })
  protected text?: string;

  @Prop({
    required: true,
  })
  protected positionCenter?: Point;

  @Prop({
    default: () => false,
    required: false,
  })
  protected isSelected?: boolean;

  protected computedArrowTextWidth = 0;
  protected computedArrowTextHeight = 0;

  @Watch('text')
  protected handleArrowTextChange(): void {
    Vue.nextTick(() => {
      this.resizeArrowText();
    });
  }

  mounted(): void {
    this.handleArrowTextChange();
  }

  get textPosition(): Point {
    if (this.positionCenter) {
      // align center of foreign object to center of arrow
      return new Point(
        this.positionCenter.x - this.computedArrowTextWidth / 2,
        this.positionCenter.y - this.computedArrowTextHeight / 2
      );
    }

    return new Point(0, 0);
  }

  protected resizeArrowText(): void {
    if (this.text && this.text.length > 0) {
      const resizerTextSizer = this.$refs.arrowtext_sizer.querySelector('div');
      if (resizerTextSizer) {
        this.computedArrowTextWidth = Math.ceil(resizerTextSizer.offsetWidth) + 2 + 10;
        this.computedArrowTextHeight = Math.ceil(resizerTextSizer.offsetHeight) + 6;
      }
    } else {
      this.computedArrowTextWidth = 0;
      this.computedArrowTextHeight = 0;
    }
  }
}
