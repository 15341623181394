import Drawable from '@/models/drawables/Drawable';
import { Serializable } from 'typescript-json-serializer';
import Point from '@/models/Point';
import Bounds from '@/models/Bounds';
import { v4 as uuidv4 } from 'uuid';
import ModelElement from '@/models/ModelElement';
import Marker from '@/models/Marker';

@Serializable()
export default class MarkerDrawable extends Drawable {
  public readonly vueComponent: string = 'marker-drawable-svg-component';
  protected readonly _belongsTo: ModelElement;
  private readonly _marker: Marker;

  constructor(type: string, marker: Marker, belongsTo: ModelElement, created?: Date) {
    super(type, '', '', new Point(0, 0), new Bounds(200, 200), created);
    this._belongsTo = belongsTo;
    this._marker = marker;
  }

  get id(): string {
    return this.marker.id;
  }

  set id(id: string) {
    this.marker.id = id;
  }

  get internalId(): string {
    return uuidv4();
  }

  get belongsTo(): ModelElement {
    return this._belongsTo;
  }

  get marker(): Marker {
    return this._marker;
  }

  get description(): string {
    return this.marker.description;
  }

  set description(value: string) {
    this.marker.description = value;
  }

  get name(): string {
    return this._marker.name;
  }

  set name(value: string) {
    this._marker.name = value;
  }
}
