import Point from '@/models/Point';
import ModelConfig from '@/models/ModelConfig';
import Node from '@/models/Node';
import Edge from '@/models/Edge';
import Intersects from 'intersects';

export default class EditorCanvasHelper {
  public static placementAllowed(element: Node, point: Point, config: ModelConfig): boolean {
    let allowed = true;
    if (!element.parentNode) {
      return allowed;
    }

    const parent = element.parentNode;
    const parentType = config.getNodeType(parent.type);

    if (parentType && parentType.insidePlacementRules) {
      parentType.insidePlacementRules.forEach((rule) => {
        if (!rule.placementAllowed(element, parent, point) && allowed) {
          allowed = false;
        }
      });
    }

    return allowed;
  }

  /**
   * Tries to find other parent by determining the intersection with other elements.
   * Returns id of new parent or false it parent was not changed
   *
   * @param elements Array of elements to change parents for
   * @param points Array of new points
   * @param nodes Array of nodes
   * @param edges Array of edges
   * @param config ModelConfig
   * @returns id of new parent or false if parent was not changed
   */
  public static maybeChangeParent(
    elements: Node[],
    points: Point[],
    nodes: Node[],
    edges: Edge[],
    config: ModelConfig
  ): string | boolean {
    if (elements.length === 0 || points.length === 0) {
      return false;
    }

    if (elements.length !== points.length) {
      return false;
    }

    let parentNode: Node | null = null;
    const parentId = elements[0].parentId;

    // first get parent and also test if edges exists
    for (const i in elements) {
      if (Object.prototype.hasOwnProperty.call(elements, i)) {
        const element = elements[i];

        // elements with different parents are selected
        if (element.parentId !== parentId) {
          return false;
        }

        // get element type
        const elementType = config.getNodeType(element.type);

        // exit on first element that cannot have a parent
        if (elementType && !elementType.canHaveParent) {
          return false;
        }

        const edgeExists =
          edges.find((edge) => {
            return edge.connectsFrom === element || edge.connectsTo === element;
          }) !== undefined;

        // if node has edges, change of parent is not allowed.
        if (edgeExists) {
          return false;
        }

        // find parent node as long as not found before
        if (!parentNode) {
          for (const j in nodes) {
            if (Object.prototype.hasOwnProperty.call(nodes, j)) {
              const node = nodes[j];

              if (node === element || node === element.parentNode) {
                continue;
              }

              const nodeType = config.getNodeType(node.type);

              // continue if node cannot have children.
              if (nodeType && !nodeType.canHaveChildren) {
                continue;
              }

              if (
                Intersects.boxBox(
                  points[i].x,
                  points[i].y,
                  element.bounds.width,
                  element.bounds.height,
                  node.startPos.x,
                  node.startPos.y,
                  node.bounds.width,
                  node.bounds.height
                )
              ) {
                parentNode = node;

                if (parentNode) {
                  break;
                }
              }
            }
          }
        }
      }
    }

    if (parentNode) {
      for (const i in elements) {
        if (Object.prototype.hasOwnProperty.call(elements, i)) {
          const element = elements[i];
          element.parentNode = parentNode;
          element.parentId = parentNode.id;
        }
      }

      return parentNode.id;
    }

    return false;
  }
}
