







import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import ModelElementMarker from '@/models/ModelElementMarker';
import { ModelElementMarkerTypeEnum } from '@/enums/ModelElementMarkerTypeEnum';

@Component({})
export default class SVGMarker extends Vue {
  @Prop({
    required: true,
  })
  protected marker!: ModelElementMarker;

  get markerComponent(): string {
    switch (this.marker.type) {
      case ModelElementMarkerTypeEnum.OK:
        return 'b-icon-check';
      case ModelElementMarkerTypeEnum.DESIGN_IMPROVEMENT:
        return 'b-icon-flag-fill';
      case ModelElementMarkerTypeEnum.ERROR:
        return 'b-icon-x';
      case ModelElementMarkerTypeEnum.UNCLEAR:
        return 'b-icon-question';
    }

    return '';
  }

  get style(): { fill: string } {
    const style = {
      fill: 'black',
    };

    switch (this.marker.type) {
      case ModelElementMarkerTypeEnum.OK:
        style.fill = 'green';
        break;
      case ModelElementMarkerTypeEnum.DESIGN_IMPROVEMENT:
        style.fill = 'orange';
        break;
      case ModelElementMarkerTypeEnum.ERROR:
        style.fill = 'red';
        break;
      case ModelElementMarkerTypeEnum.UNCLEAR:
        style.fill = 'blue';
        break;
    }

    return style;
  }
}
