export default class DrawAreaBounds {
  private _top = 0;
  private _left = 0;
  private _bottom = 0;
  private _right = 0;

  constructor(top?: number, left?: number, right?: number, bottom?: number) {
    if (top) {
      this._top = top;
    }
    if (left) {
      this._left = left;
    }
    if (bottom) {
      this._bottom = bottom;
    }
    if (right) {
      this._right = right;
    }
  }

  get top(): number {
    return this._top;
  }

  set top(value: number) {
    this._top = value;
  }

  get left(): number {
    return this._left;
  }

  set left(value: number) {
    this._left = value;
  }

  get bottom(): number {
    return this._bottom;
  }

  set bottom(value: number) {
    this._bottom = value;
  }

  get right(): number {
    return this._right;
  }

  set right(value: number) {
    this._right = value;
  }
}
