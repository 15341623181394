import Drawable from '@/models/drawables/Drawable';
import { Serializable } from 'typescript-json-serializer';
import Point from '@/models/Point';
import Bounds from '@/models/Bounds';
import { v4 as uuidv4 } from 'uuid';
import TextAnnotation from '@/models/TextAnnotation';
import ModelElement from '@/models/ModelElement';

@Serializable()
export default class TextAnnotationDrawable extends Drawable {
  public readonly vueComponent: string = 'text-annotation-drawable-svg-component';
  protected readonly _belongsTo: ModelElement;
  protected readonly _textAnnotation: TextAnnotation;

  constructor(type: string, textAnnotation: TextAnnotation, belongsTo: ModelElement, created?: Date) {
    super(type, '', '', new Point(0, 0), new Bounds(200, 200), created);
    this._belongsTo = belongsTo;
    this._textAnnotation = textAnnotation;
  }

  get id(): string {
    return this.textAnnotation.id;
  }

  set id(id: string) {
    this.textAnnotation.id = id;
  }

  get internalId(): string {
    return uuidv4();
  }

  get belongsTo(): ModelElement {
    return this._belongsTo;
  }

  get textAnnotation(): TextAnnotation {
    return this._textAnnotation;
  }

  get description(): string {
    return this._textAnnotation.description;
  }

  set description(value: string) {
    this._textAnnotation.description = value;
  }
}
