
import { Component } from 'vue-property-decorator';
import EditorCanvas from '@/components/Editor/EditorCanvas.vue';
import { mixins } from 'vue-class-component';

@Component({})
export default class ReviewCanvas extends mixins(EditorCanvas) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any
  handleDrag({ beforeTranslate, target, transform }: { beforeTranslate: any; target: any; transform: any }): void {
    // do nothing
  }

  handleDragStart({ set }: { set: any }) {
    // do nothing
  }

  handleDragEnd() {
    // do nothing
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected handleDrop(e: Event): void {
    // do nothing
  }

  protected deleteSelectedElements(): void {
    // do nothing
  }

  protected get resizable(): boolean {
    return false;
  }

  protected get draggable(): boolean {
    return false;
  }
}
