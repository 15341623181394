import Component, { mixins } from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import Point from '@/models/Point';
import SelectedModelElement from '@/models/SelectedModelElement';
import Node from '@/models/Node';
import EditorCanvasHelper from '@/serializer/EditorCanvasHelper';
import Drawable from '@/models/drawables/Drawable';
import Model from '@/models/Model';
import { Toasts } from '@/mixins/ToastMixins';
import ModelConfig from '@/models/ModelConfig';
import { ModelMixin } from '@/mixins/ModelMixin';
import Edge from '@/models/Edge';
import { findNodeWithId } from '@/serializer/helpers';
import Attribute from '@/models/Attribute';
import Bounds from '@/models/Bounds';

@Component({})
export class EditorZoom extends Vue {
  @State('scale', { namespace: 'modelEditor' })
  protected currentZoom?;

  @Action('setScale', { namespace: 'modelEditor' })
  protected setZoom!: (val: number) => void;
}

@Component({})
export class EditorEndlessViewPort extends Vue {
  protected internalViewPort: Point = new Point(0, 0);
}

@Component({})
export class DragscrollMixin extends mixins(EditorZoom, EditorEndlessViewPort) {
  public dragscrollEnabled = false;
  public isDragging = false;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  protected handleDragScrollMove(e: any): void {
    const ratio = (2500 * this.currentZoom) / window.innerWidth;
    this.internalViewPort = new Point(
      this.internalViewPort.x - e.detail.deltaX * ratio,
      this.internalViewPort.y - e.detail.deltaY * ratio
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    (this.$refs.moveable as any).updateRect();
  }

  protected handleDragScrollStart(): void {
    this.isDragging = true;
  }

  protected handleDragScrollEnd(): void {
    setTimeout(() => {
      this.isDragging = false;
    }, 500);
  }
}

@Component({})
export class MovableMixin extends mixins(DragscrollMixin, ModelMixin, Toasts) {
  /**
   * Injected from store
   */
  @State('selectedElements', { namespace: 'modelEditor' })
  public selectedElements!: SelectedModelElement[];

  /**
   *  Holds the last valid position of selected element
   */
  protected lastPosition: Point = new Point(0, 0);

  protected lastSelectEvent: MouseEvent | null = null;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any
  public targets: any[] = [];
  public isResizing = false;

  protected draggingTimeout?: number = undefined;
  protected lastGroupPositions: Point[] = [];

  protected resizePosition: Point = new Point(0, 0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any
  public moveable: Record<string, any> = {
    throttleDrag: 0,
    throttleResize: 0,
    throttleScale: 0,
    keepRatio: false,
    scalable: false,
    rotatable: false,
    throttleRotate: 0,
    snappable: true,
    pinchable: false, // ["draggable", "resizable", "scalable", "rotatable"]
    origin: false,
    // target: this.selectedElements
  };

  @Action('setSelectedElements', { namespace: 'modelEditor' })
  public setSelectedElements!: (selectedElements: SelectedModelElement[]) => void;

  @Watch('selectedElements', { deep: true })
  public selectedElementChanged(newVal: SelectedModelElement[]): void {
    Vue.nextTick(() => {
      if (newVal.length > 0) {
        this.targets = [].slice.call(
          newVal.map((selectedElement: SelectedModelElement) => {
            return this.getTargetDomNode(selectedElement.target);
          })
        );

        if (this.targets.length > 0) {
          // this.target = this.targets[0];
        }

        // eslint-disable-next-line
        (this.$refs.moveable as any).setState({
          target: this.targets,
        });

        if (this.targets.length === 0) {
          // eslint-disable-next-line
          (this.$refs.moveable as any).setState({
            dragTarget: undefined,
          });
        }

        if (this.lastSelectEvent) {
          // set dragTarget on new select to force movable to immediately trigger drag events
          // eslint-disable-next-line
          (this.$refs.moveable as any).setState(
            {
              dragTarget: this.targets[0],
            },
            () => {
              let ev: any = this.lastSelectEvent;
              if (this.selectedElements.length > 1) {
                ev = new MouseEvent('mousedown');
                ev.inputEvent = {
                  target: document.querySelector('body'),
                };
              }
              // eslint-disable-next-line
              (this.$refs.moveable as any).dragStart(ev);
            }
          );
        }
      } else {
        this.targets = [];
      }
    });
  }

  public handleDrag({
    beforeTranslate,
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    beforeTranslate: any;
  }): void {
    if (this.selectedElements.length === 1) {
      this.selectedElements[0].modelElement.startPos = new Point(beforeTranslate[0], beforeTranslate[1]);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  public handleDragStart({ set }: { set: any }): void {
    clearTimeout(this.draggingTimeout);
    this.isDragging = true;
    this.dragscrollEnabled = false;
    if (this.selectedElements.length === 1) {
      const startPoint = new Point(
        this.selectedElements[0].modelElement.startPos.x,
        this.selectedElements[0].modelElement.startPos.y
      );
      this.lastPosition = startPoint;
      set([startPoint.x, startPoint.y]);
    }
  }

  public handleDragEnd(): void {
    if (this.selectedElements.length === 1) {
      const selectedElement = this.selectedElements[0];
      const endPoint = new Point(
        this.selectedElements[0].modelElement.startPos.x,
        this.selectedElements[0].modelElement.startPos.y
      );

      if (selectedElement.modelElement instanceof Node) {
        // check if parent is changed?
        // change of parent is only allowed if there are no existing edges
        EditorCanvasHelper.maybeChangeParent(
          [selectedElement.modelElement],
          [endPoint],
          this.currentModel.nodes,
          this.currentModel.edges,
          this.currentConfig
        );
      }

      // test if placement on (possibly new) parent is allowed
      if (
        this.selectedElements[0].modelElement instanceof Node &&
        !this.placementAllowed(this.selectedElements[0].modelElement, endPoint)
      ) {
        this.showToast(
          'New Position not allowed',
          'Position of element will be set back to previous position',
          'danger'
        );
        this.selectedElements[0].modelElement.startPos = this.lastPosition;
        if (this.$refs.moveable) {
          Vue.nextTick(() => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
            (this.$refs.moveable as any).updateRect();
          });
        }
      } else {
        if (selectedElement.modelElement instanceof Drawable && selectedElement.modelElement.bounds) {
          selectedElement.modelElement.endPos = new Point(
            selectedElement.modelElement.startPos.x + selectedElement.modelElement.bounds.width,
            selectedElement.modelElement.startPos.y + selectedElement.modelElement.bounds.height
          );
        }
      }
    }

    this.draggingTimeout = setTimeout(() => {
      this.isDragging = false;
    }, 200);
  }

  protected placementAllowed(element: Node, point: Point): boolean {
    return EditorCanvasHelper.placementAllowed(element, point, this.currentConfig);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any
  public handleDragGroupStart({ events }: { events: any }): void {
    this.lastGroupPositions = [];
    events.forEach((ev, i) => {
      if (this.selectedElements[i] === undefined || this.selectedElements[i].modelElement instanceof Edge) {
        return;
      }
      const startPoint = new Point(
        this.selectedElements[i].modelElement.startPos.x,
        this.selectedElements[i].modelElement.startPos.y
      );
      this.lastGroupPositions.push(startPoint);
      ev.set([startPoint.x, startPoint.y]);
    });

    this.dragscrollEnabled = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any
  public handleDragGroup({ events }: { events: any }): void {
    events.forEach(({ beforeTranslate }, i) => {
      if (this.selectedElements[i].modelElement instanceof Edge) {
        return;
      }
      this.selectedElements[i].modelElement.startPos = new Point(beforeTranslate[0], beforeTranslate[1]);
    });
  }

  public handleDragGroupEnd(): void {
    let allAllowed = true;

    const oldParentIds = this.selectedElements
      .filter((selectedElement) => selectedElement.modelElement instanceof Node)
      .map((selectedElement) => (selectedElement.modelElement as Node).parentId);

    // check if parent is changed?
    // change of parent is only allowed if there are no existing edges
    EditorCanvasHelper.maybeChangeParent(
      this.selectedElements
        .filter((selectedElement) => selectedElement.modelElement instanceof Node)
        .map((selectedElement) => selectedElement.modelElement as Node),
      this.selectedElements
        .filter((selectedElement) => selectedElement.modelElement instanceof Node)
        .map((selectedElement) => selectedElement.modelElement.startPos),
      this.currentModel.nodes,
      this.currentModel.edges,
      this.currentConfig
    );
    // test if placement on (possibly new) parent is allowed

    this.selectedElements.forEach((element) => {
      const endPoint = new Point(element.modelElement.startPos.x, element.modelElement.startPos.y);
      if (
        !allAllowed ||
        (element.modelElement instanceof Node && !this.placementAllowed(element.modelElement, endPoint))
      ) {
        allAllowed = false;
        // alert('New Position not allowed. Reset to previous position');
      }
    });

    if (!allAllowed) {
      // rollback parentId
      this.selectedElements.forEach((selectedElement, index) => {
        if (selectedElement.modelElement instanceof Node) {
          const oldParentId = oldParentIds[index];
          selectedElement.modelElement.parentId = oldParentId;
          if (oldParentId) {
            selectedElement.modelElement.parentNode = findNodeWithId(this.currentModel.nodes, oldParentId);
          } else {
            selectedElement.modelElement.parentNode = undefined;
          }

          selectedElement.modelElement.startPos = new Point(
            this.lastGroupPositions[index].x,
            this.lastGroupPositions[index].y
          );
          if (this.$refs.moveable) {
            Vue.nextTick(() => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
              (this.$refs.moveable as any).updateRect();
            });
          }
        }
      });
    }
  }

  /**
   * RESIZE Events
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any
  protected handleResizeStart({ set, dragStart }: { set: any; dragStart: any }): void {
    if (this.selectedElements.length === 1) {
      this.isResizing = true;
      this.resizePosition = new Point(
        this.selectedElements[0].modelElement.startPos.x,
        this.selectedElements[0].modelElement.startPos.y
      );
      // setOrigin(['%', '%']);

      const widthAttribute = this.selectedElements[0].modelElement.attributes.find((attr) => {
        return attr.name === 'width';
      });
      const heightAttribute = this.selectedElements[0].modelElement.attributes.find((attr) => {
        return attr.name === 'height';
      });

      if (widthAttribute && heightAttribute) {
        set([parseFloat(widthAttribute.value), parseFloat(heightAttribute.value)]);
      } else {
        set([this.selectedElements[0].modelElement.bounds.width, this.selectedElements[0].modelElement.bounds.height]);
      }

      // If a drag event has already occurred, there is no dragStart.
      dragStart &&
        dragStart.set([
          this.selectedElements[0].modelElement.startPos.x,
          this.selectedElements[0].modelElement.startPos.y,
        ]);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any
  protected handleResize({ width, height, drag }: { width: string; height: string; drag: any }): void {
    if (this.selectedElements.length === 1 && this.selectedElements[0].modelElement.resizeTarget) {
      const sizeTarget = this.getTargetDomNode(this.selectedElements[0].modelElement.resizeTarget) as SVGElement;

      let translateTarget: SVGElement | null = null;
      if (this.selectedElements[0].modelElement.translateTarget) {
        translateTarget = this.getTargetDomNode(this.selectedElements[0].modelElement.translateTarget) as SVGElement;
      }
      if (this.selectedElements[0].modelElement instanceof Drawable) {
        if (sizeTarget) {
          sizeTarget.style.width = `${width}px`;
          sizeTarget.style.height = `${height}px`;
        }
      } else {
        const widthAttribute = this.selectedElements[0].modelElement.attributes.find((attr) => {
          return attr.name === 'width';
        });
        const heightAttribute = this.selectedElements[0].modelElement.attributes.find((attr) => {
          return attr.name === 'height';
        });
        if (widthAttribute && heightAttribute) {
          if (sizeTarget) {
            sizeTarget.setAttribute('width', width);
            sizeTarget.setAttribute('height', height);
          }
        }
      }
      if (translateTarget) {
        this.resizePosition = new Point(drag.beforeTranslate[0], drag.beforeTranslate[1]);
        /* translateTarget.setAttribute(
          'transform',
          `translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px)`); */
        translateTarget.style.transform = `translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px)`;
      }
    }
  }

  protected handleResizeEnd(): void {
    this.isResizing = false;
    if (this.selectedElements.length === 1 && this.selectedElements[0].modelElement.resizeTarget) {
      const sizeTarget = this.getTargetDomNode(this.selectedElements[0].modelElement.resizeTarget) as SVGElement;

      const widthAttribute = this.selectedElements[0].modelElement.attributes.find((attr) => {
        return attr.name === 'width';
      });
      const heightAttribute = this.selectedElements[0].modelElement.attributes.find((attr) => {
        return attr.name === 'height';
      });
      if (sizeTarget) {
        if (widthAttribute && heightAttribute) {
          const w = sizeTarget.getAttribute('width');
          const h = sizeTarget.getAttribute('height');

          if (w && h) {
            const newWidthAttribute = new Attribute('width', w);
            const newHeightAttribute = new Attribute('height', h);

            const attributes: Attribute[] = [].slice.call(
              this.selectedElements[0].modelElement.attributes.filter((attr) => {
                return attr.name !== 'height' && attr.name !== 'width';
              })
            );

            attributes.push(newWidthAttribute);
            attributes.push(newHeightAttribute);

            Vue.set(this.selectedElements[0].modelElement, 'attributes', attributes);
          }
        } else {
          const width = parseInt(sizeTarget.style.width.replace('px', ''));
          const height = parseInt(sizeTarget.style.height.replace('px', ''));

          (this.selectedElements[0].modelElement as Drawable).bounds = new Bounds(width, height);
          (this.selectedElements[0].modelElement as Drawable).endPos = new Point(
            this.resizePosition.x + (this.selectedElements[0].modelElement as Drawable).bounds.width,
            this.resizePosition.y + (this.selectedElements[0].modelElement as Drawable).bounds.height
          );
        }
      }

      this.selectedElements[0].modelElement.startPos = new Point(this.resizePosition.x, this.resizePosition.y);
    }
  }

  protected getTargetDomNode(elementId: string): HTMLElement | SVGElement | null {
    return document.querySelector("[data-element-id='" + elementId + "']");
  }

  protected get draggable(): boolean {
    if (this.selectedElements.length === 1) {
      const selectedElement = this.selectedElements[0];
      if (selectedElement.modelElement instanceof Edge) {
        return false;
      }
    }

    return true;
  }

  protected get resizable(): boolean {
    if (this.selectedElements.length === 1) {
      if (
        this.selectedElements[0].modelElement instanceof Drawable ||
        this.selectedElements[0].modelElement.type === 'SystemBoundary'
      ) {
        return true;
      }
    }
    return false;
  }
}
