










import { Prop, Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import Config from '@/config';
import Point from '@/models/Point';

@Component({})
export default class EdgeClickHelper extends Vue {
  protected static ID_APPENDIX = '-arrow';

  @Prop({
    default: () => '',
    required: true,
  })
  protected id?: string;

  @Prop({
    default: () => '',
    required: true,
  })
  protected startPosition?: Point;

  @Prop({
    default: () => '',
    required: true,
  })
  protected endPosition?: Point;

  get elementId(): string {
    return this.id + EdgeClickHelper.ID_APPENDIX;
  }

  /**
   * Each edge has some area around to have a click threshold -> Easier to click on edge.
   * This function shows this area if enabled in config.
   */
  get edgeClickHelperOpacity(): number {
    if (Config.EDITOR.DEBUG_EDGE_CLICK_HELPERS) {
      return 0.7;
    }

    return 0;
  }

  get arrowWidth(): number {
    if (this.startPosition && this.endPosition) {
      return Math.sqrt(
        Math.pow(this.endPosition.x - this.startPosition.x, 2) + Math.pow(this.endPosition.y - this.startPosition.y, 2)
      );
    }

    return 0;
  }

  get deltaX(): number {
    if (this.endPosition && this.startPosition) {
      return this.endPosition.x - this.startPosition.x;
    }

    return 0;
  }

  get deltaY(): number {
    if (this.endPosition && this.startPosition) {
      return this.endPosition.y - this.startPosition.y;
    }

    return 0;
  }

  get rotation(): number {
    return (Math.atan2(this.deltaY, this.deltaX) * 180) / Math.PI;
  }

  get transformString(): string {
    return 'transform: rotate(' + this.rotation + 'deg)';
  }

  get arrowPath(): string {
    if (this.startPosition && this.endPosition) {
      let path = 'M';

      const offset = 10;

      path += '0,-' + offset;
      path += ' L' + this.arrowWidth + ',-' + offset;
      path += ' L' + this.arrowWidth + ',' + offset;
      path += ' 0, ' + offset;

      path += 'Z';

      return path;
    }

    return '';
  }
}
