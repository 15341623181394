import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class ModelAnalysisResult {
  @JsonProperty('analysis')
  private readonly _analysisType: string;
  @JsonProperty('modelId')
  private readonly _modelId: string;
  @JsonProperty('runtimeMillis')
  private readonly _runtimeMillis: number;
  @JsonProperty('results')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  private readonly _results: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  constructor(analysisType: string, modelId: string, runtimeMillis: number, results: any) {
    this._analysisType = analysisType;
    this._modelId = modelId;
    this._runtimeMillis = runtimeMillis;
    this._results = results;
  }

  get analysisType(): string {
    return this._analysisType;
  }

  get modelId(): string {
    return this._modelId;
  }

  get runtimeMillis(): number {
    return this._runtimeMillis;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  get results(): any {
    return this._results;
  }

  get asFormattedString(): string {
    let formattedString = 'Analysis Type:\n   ' + this.analysisType;
    formattedString += '\nAnalysis Duration:\n  ' + this.runtimeMillis + ' ms';
    formattedString += '\nResults:\n  ';
    formattedString += JSON.stringify(this.results)
      .replace(/["{}]/gi, '')
      .replace(/[,]/gi, '\n  ')
      .replace(/[:]/gi, ': ');
    return formattedString;
  }
}
