import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import Model from '@/models/Model';
import ModelConfig from '@/models/ModelConfig';
import { Action, State } from 'vuex-class';

@Component({})
export class ModelMixin extends Vue {
  @State('currentModel', { namespace: 'modelEditor' })
  protected currentModel!: Model;
  @State('currentConfig', { namespace: 'modelEditor' })
  protected currentConfig!: ModelConfig;

  @Action('setCurrentModel', { namespace: 'modelEditor' })
  public setCurrentModel!: (model: Model | undefined) => void;

  @Action('setCurrentConfig', { namespace: 'modelEditor' })
  public setCurrentConfig!: (config: ModelConfig | undefined) => void;
}
